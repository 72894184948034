<template>
  <div>

    <box-head
      :titleText="title"
      :showDropdown="false"
      :showBackArrow="true"
      :descriptionText="description"
    />

    <div class="box-content">
      <ul>
        <li>
          {{contacts.name}}
        </li>
        <li>
          <el-link class="custom-link" v-bind:href="'mailto:'+contacts.email">
            {{contacts.email}}
          </el-link>
        </li>
        <li v-if="contacts.displayPhone">
          <el-link class="custom-link" v-bind:href="`tel:+${contacts.phone}`">
            {{contacts.phone | phone}}
          </el-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import BoxHead from '../components/BoxHead.vue';
  import Api from '../services/api.js';
  
  export default {
    components: {
      BoxHead,
    },
    data() {
      return {
        title: this.$i18n.t('contactsPage.title'),
        description: this.$i18n.t('contactsPage.description'),
        contacts: {
          name: "",
          phone: "",
          displayPhone: false,
          email: ""
        }
      }
    },
    mounted() {
      Api.getContacts().then((response) => {
        this.contacts = response.data;
      });
    },
    computed: {
      hiddenFormShow() {
        return this.$store.getters.GET_HIDDEN_FORM_SHOW;
      },
    }
  }
</script>

<style scoped lang="scss">
  ul {
    display: block;
    padding:15px 0 0;
    margin:0;
    list-style:none;
    li {
      margin-bottom: 16px;
    }
    a {
      font-family: $font2;
      font-weight: bold;
      font-size: 15px;
      letter-spacing: 0.02em;
      span {
        font-family: $font2;
        font-weight: bold;
      }
    }
  }
</style>
<template>
  <div class="head">
    <div
      class="top"
      :class="{style: showBackArrow}"
      >
      <a
        href="#"
        class="back-arrow"
        v-if="showBackArrow"
        v-on:click.prevent="back()" >
        
      </a>
      <h2
        v-if="showTitle"
        v-html="titleText">
      </h2>
      <a
        v-if="showLink"
        href="#"
        class="link ghost-link">
        ОК
      </a>
      <el-dropdown
        v-if="showDropdown"
        :class="{'active': activeDrop}"
        @visible-change="checkActiveDrop()"
        trigger="click">
        <span class="el-dropdown-link">
          <i class="el-icon-s-tools"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <a href="#" @click.prevent="openModal()">{{ $t('headLinks.auth') }}</a>
          </el-dropdown-item>
          <el-dropdown-item>
            <a :href="HELP_LINK" target="_blank">{{ $t('headLinks.help') }}</a>
          </el-dropdown-item>
          <el-dropdown-item>
            <router-link :to="`/${REQUEST_ID}/contacts`" tag="span">{{ $t('headLinks.contacts') }}</router-link>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <p v-if="showDescription" v-html="descriptionText"></p>
  </div>
</template>

<script>
  import { eventBus } from '../event-bus.js';
  import { HELP_LINK, REQUEST_ID } from '../config.js';
  export default {
    props: {
      showDropdown: {
        type: Boolean,
        default: true,
      },
      showBackArrow: {
        type: Boolean,
        default: false,
      },
      showTitle: {
        type: Boolean,
        default: true,
      },
      titleText: {
        type: String,
        default: null,
      },
      showDescription: {
        type: Boolean,
        default: true,
      },
      descriptionText: {
        type: String,
        default: ''
      },
      showLink: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        modalOpen: false,
        activeDrop: false,
        HELP_LINK,
        REQUEST_ID,
      }
    },
    methods: {
      back() {
        this.$router.push({ name: 'Home', params: {pathMatch: this.$route.params.pathMatch} });
      },
      openModal() {
        this.modalOpen = true;
        eventBus.$emit('modal-open',this.modalOpen);

        this.$store.dispatch('SET_CLICKED_LINK', true);
      },
      checkActiveDrop() {
        this.activeDrop = !this.activeDrop;
      }
    },
    mounted() {
     eventBus.$on('modal-open',(modalOpen)=>{
        this.modalOpen = modalOpen;
      });
    }
  }
</script>

<style scoped lang="scss">

  p {
    margin: 0 0 10px;
  }

  .head {
    min-height: 89px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 -20px;
    padding: 0 10px 0 20px;
  }

  .back-arrow {
    position: absolute;
    top: 8px;
    left: -6px;
    width: 24px;
    height: 24px;
    background-image: url('../assets/images/back_arr.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 13px;
    position: relative;
    width: 100%;
    &.style {
      padding-left: 32px;
    }
  }

  h2 {
    margin-right: 10px;
    flex: 1;
  }

  .link {
    margin-right: 3px;
    margin-top: 8px;
  }


  ::v-deep .el-dropdown {
    right: -6px;
    top: -6px;
    &.active {
      .el-dropdown-link {
        color: $blue;
      }
    }
    &-link {
      transform: rotate(90deg);
      display: block;
      font-size: 24px;
      color: $black;
      padding: 14px;
      cursor: pointer;
      transition: all .35s ease 0s;
      &:hover {
        color: $blue;
      }
    }
  }
  .el-dropdown-menu {
    margin: 0 !important;
    padding: 0;
    border:none;
    border-radius: 4px;
    &__item {
      line-height: 1;
      color: $black;
      padding: 0;
      &:focus,
      &:hover {
        color: $black;
        background: $hoverBg;
      }
    }
    a, span {
      padding: 0 20px;
      line-height: 46px;
      display: block;
      text-decoration: none;
    }
    &::v-deep {
      .popper__arrow {
        display: none !important;
      }
    }
  }
  .el-dropdown-menu__item:focus,
  .el-dropdown-menu__item:not(.is-disabled):hover {
    color: $black;
    background: $hoverBg;
  }

</style>